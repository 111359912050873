//业务配置
const env = process.env.NODE_ENV

const Setting = {

    // Cookies 默认保存时间，单位：天
    cookiesExpires: 1,
    // 路由模式，可选值为 history 或 hash
    routerMode: 'history',
    // 接口请求地址
    // apiBaseURL: env === 'development' ? 'http://jiankangapi.demo.alawang.com' : 'http://jiankangapi.demo.alawang.com',
    apiBaseURL: env === 'development' ? 'http://jiankangapi.demo.alawang.com' : 'https://api.task.jkyxpd.com',
    //上传文件接口
    // uploadApiUrl: env === 'development' ? 'http://jiankangapi.demo.alawang.com/api/common/upload' : 'http://jiankangapi.demo.alawang.com/api/common/upload',
    uploadApiUrl: env === 'development' ? 'http://jiankangapi.demo.alawang.com/api/common/upload' : 'https://api.task.jkyxpd.com/api/common/upload',
    //配置内容管理
    i18n: {
        // 默认语言
        default: 'zh-CN',
        // 是否根据用户电脑配置自动设置语言（仅第一次有效）
        auto: false,
    },
    currentUrl: window.document.location.protocol + '//' + window.document.location.host + '/',
}

export default Setting
