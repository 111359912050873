import store from '@/store'
import axios from 'axios'
import swal from 'sweetalert2'
import Setting from '@/setting'
import router from '@/router'
import util from '@/libs/util'
import qs from 'qs'

// 创建一个错误
function errorCreate(msg, icon = 'error') {

    const err = new Error(msg)

    errorLog(err, icon)
    throw err
}

// 记录和显示错误
function errorLog(err, icon = 'error') {

    swal.fire({
        title: err.message,
        icon: icon,
        confirmButtonText: '确定',
        confirmButtonColor: '#2d8cf0',
        cancelButtonText: '取消',
    })
}

// 创建一个 axios 实例
const service = axios.create({
    baseURL: Setting.apiBaseURL,
    // timeout: 5000 // 请求超时时间
    transformRequest: [(data) => qs.stringify(data, {arrayFormat: 'indices'})],
})

service.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
// 请求拦截器
service.interceptors.request.use(
    (config) => {
        store.commit('admin/loader/start')

        // 在请求发送之前做一些处理
        const token = util.cookies.get('token')
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        config.headers['Authorization'] = 'Bearer ' + token
        return config
    },
    (error) => {
        errorCreate(dataAxios.message, 'warning')
        // 发送失败
        console.log(error)
        Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {

        store.commit('admin/loader/end')

        // dataAxios 是 axios 返回数据中的 data
        const dataAxios = response.data

        // return dataAxios;

        // 这个状态码是和后端约定的
        const code = dataAxios.code

        // 根据 code 进行判断
        if (code == null) {
            // 如果没有 code 代表这不是项目后端开发的接口
            return dataAxios
        } else {
            // 有 code 代表这是一个后端接口 可以进行进一步的判断
            switch (code) {
                case 200:
                    // [ 示例 ] code === 0 代表没有错误
                    // console.log(dataAxios.data, 12232)
                    return dataAxios.data
                case 201:
                    return dataAxios.data
                // case 'xxx':
                //     // [ 示例 ] 其它和后台约定的 code
                //     errorCreate(`[ code: xxx ] ${dataAxios.msg}: ${response.config.url}`);
                //     break;
                case 218:
                    return dataAxios.data
                default:
                    // 不是正确的 code
                    errorCreate(dataAxios.message, 'warning')
            }
        }
    },
    (error) => {

        if (error && error.response && error.response.status === 401) {
            error.message = '未授权，请登录'
            router.push('/login')
        }

        store.commit('admin/loader/end')
        if (error && error.response) {
            switch (error.response.data.code) {
                case 400:
                    error.message = error.response.data.message?error.response.data.message:'请求错误'
                    break
                case 401:
                    error.message = '未授权，请登录'
                    break
                case 403:
                    error.message = '拒绝访问'
                    break
                case 404:
                    error.message = `请求地址出错: ${error.response.config.url}`
                    break
                case 408:
                    error.message = '请求超时'
                    break
                case 422:
                    error.message = ` ${error.response.data.message}`
                    break
                case 500:
                    error.message = '内部错误'
                    break
                case 501:
                    error.message = '服务未实现'
                    break
                case 502:
                    error.message = '网关错误'
                    break
                case 503:
                    error.message = '服务不可用'
                    break
                case 504:
                    error.message = '网关超时'
                    break
                case 505:
                    error.message = 'HTTP版本不受支持'
                    break
                default:
                    break
            }
        }

        errorLog(error)
        return Promise.reject(error)
    }
)

export default service
