<template>
    <el-aside :class="isCollapse ? 'left_side collapse' : 'left_side'">
        <div class="logo_container">
            <!-- <img src="../../assets/images/logo/logo.png" alt /> -->
            健康云后台管理系统
        </div>
        <div class="logo_container logo_thumb">
            <!-- <img src="../../assets/images/logo/logo_thumb.png" alt /> -->
        </div>
        <el-scrollbar>
            <el-menu
                    background-color="#273043"
                    text-color="#ccc"
                    active-text-color="#1890ff"
                    unique-opened
                    :collapse="isCollapse"
                    :collapse-transition="false"
                    :router="true"
                    :default-active="$route.path"
                    :default-openeds="defaultOpeneds"
                    class="menu_vertical"
            >
                <el-menu-item index="/" v-show="allow('statistical')">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">首页</span>
                </el-menu-item>
                <el-menu-item index="/enterprise" v-show="allow('customer')">
                    <i class="el-icon-office-building"></i>
                    <span slot="title">医药企业</span>
                </el-menu-item>
                <el-menu-item index="/program" v-show="allow('project')">
                    <i class="el-icon-files"></i>
                    <span slot="title">项目管理</span>
                </el-menu-item>
                <el-menu-item index="/task" v-show="allow('task')">
                    <i class="el-icon-notebook-2"></i>
                    <span slot="title">任务管理</span>
                </el-menu-item>
                <el-menu-item index="/payment" v-show="allow('payment')">
                    <i class="el-icon-document-checked"></i>
                    <span slot="title">完工支付</span>
                </el-menu-item>
                <el-menu-item index="/taker" v-show="allow('taker')">
                    <i class="el-icon-user"></i>
                    <span slot="title">制作者</span>
                </el-menu-item>
                <el-menu-item index="/agreement" v-show="allow('agreement')">
                    <i class="el-icon-document-copy"></i>
                    <span slot="title">合同管理</span>
                </el-menu-item>
                <el-submenu index="system" v-show="allow('system')">
                    <template slot="title">
                        <div class="submenu_title">
                            <i class="el-icon-setting"></i>
                            <span>系统设置</span>
                        </div>
                    </template>
                    <el-menu-item index="/user" v-show="allow('system.admin')">&nbsp;&nbsp;&nbsp;用户管理</el-menu-item>
                    <el-menu-item index="/role" v-show="allow('system.role')">&nbsp;&nbsp;&nbsp;角色管理</el-menu-item>
                    <el-menu-item index="/permission" v-show="allow('system.permission')"
                    >&nbsp;&nbsp;&nbsp;权限管理
                    </el-menu-item
                    >
                    <el-menu-item index="/park" v-show="allow('system.company')">&nbsp;&nbsp;&nbsp;子公司</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-scrollbar>
    </el-aside>
</template>

<script>
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                isCollapse: false,
                // navActiveIndex: '/', //当前菜单项
                defaultOpeneds: [''], //菜单默认展开项
            }
        },
        mounted() {
            this.listingIsCollapse()
        },
        methods: {
            listingIsCollapse() {
                this.$Bus.$on('listingIsCollapse', () => {
                    this.isCollapse = !this.isCollapse
                })
            },
            // jumpToNav(url) {
            //     this.navActiveIndex = url
            // },
        },
    }
</script>
