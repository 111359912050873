<template>
  <div>
    <el-container v-if="$route.meta.keepAlive">
      <leftMenu />
      <el-main>
        <headerTop />
        <div class="main_content">
          <router-view />
        </div>
      </el-main>
    </el-container>
    <router-view v-else />
  </div>
</template>

<script>
import headerTop from "./components/public/header";
import leftMenu from "./components/public/left_menu";
export default {
  data() {
    return {};
  },
  components: {
    headerTop,
    leftMenu
  },
  methods: {}
};
</script>
