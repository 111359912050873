import request from '@/plugins/request'

const res = 'customer'

export default {
  //获取企业的列表
  index(params) {
    return request({
      url: `api/${res}`,
      method: 'get',
      params,
    })
  },
  //添加企业
  store(params){
    return request({
        url:`api/${res}`,
        method:'post',
        data: params
    })
  },
  //获取单条企业详情
  show(id){
    return request({
        url:`api/${res}/`+id,
        method:'get',
    })
  },
  update(params){
    return request({
        url:`api/${res}/`+params.id,
        method:'put',
        data:params
    })
   }
}
