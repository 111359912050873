import request from '@/plugins/request'

const res = 'taker'

export default {
    saveAgreement(params){
        return request({
            url:`api/${res}/saveAgreement`,
            method:'post',
            data:params
        })
    },
    setStatus(params){
        return request({
            url:`api/${res}/setStatus`,
            method:'post',
            data:params
        })
    }
}
