import request from '@/plugins/request'

const res = 'company'

export default {
    //获取企业的列表
    index(params) {
        return request({
            url: `api/${res}`,
            method: 'get',
            params,
        })
    },
}
