import request from '@/plugins/request'

export default {
    //给用户分配角色
    assignRole(res, params) {
        return request({
            url: `api/${res}/assignRole`,
            method: 'post',
            data: params
        })
    },

    setRolePermission(res, params) {
        return request({
            url: `api/${res}/assignPermissions`,
            method: 'post',
            data: params
        })
    },
    getPermission(res){
        return request({
            url: `api/${res}/permission`,
            method: 'get',

        })
    }
}
