import request from '@/plugins/request'

const res = 'admin'

export default {
    login(params) {
        return request({
            url: `api/${res}/login`,
            method: 'post',
            data: params,
        })
    },
    loginOut(params) {
        return request({
            url: `api/${res}/loginOut`,
            method: 'get',
            params,
        })
    },
    index(params) {
        return request({
            url: `api/${res}`,
            method: 'get',
            params
        })
    },

}
