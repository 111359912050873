import Vue from "vue";
import VueRouter from "vue-router";
import util from '@/libs/util'
Vue.use(VueRouter);
// // 解决报错
// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// // push
//
// VueRouter.prototype.push = function push (location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }
// // replace
// VueRouter.prototype.replace = function push (location, onResolve, onReject) {
//     if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//     return originalReplace.call(this, location).catch(err => err)
// }
const routes = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: "首页", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/main/index")
  },
  {
    path: "/index",
    name: "IIndex",
    meta: { title: "首页", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/main/index")
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "登录", keepAlive: false },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/Login")
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    meta: {title: "重置密码",keepAlive: false},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/ResetPassword")
  },
  {
    path: "/enterprise",
    name: "Enterprise",
    meta: { title: "企业管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/enterprise/enterprise")
  },
  {
    path: "/program",
    name: "Program",
    meta: { title: "项目管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/program/program")
  },
  {
    path: "/task",
    name: "Task",
    meta: { title: "任务管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/task/task")
  },
  {
    path: "/payment",
    name: "Payment",
    meta: { title: "完工支付", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/payment/payment")
  },
  {
    path: "/agreement",
    name: "Agreement",
    meta: { title: "合同管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/agreement/agreement")
  },
  {
    path: "/taker",
    name: "Taker",
    meta: { title: "猎手管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/taker/taker")
  },

  {
    path: "/user",
    name: "User",
    meta: { title: "用户管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setting/user")
  },
  {
    path: "/permission",
    name: "Permission",
    meta: { title: "权限管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setting/permission")
  },
  {
    path: "/role",
    name: "Role",
    meta: { title: "角色管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/setting/role")
  },
  {
    path: "/park",
    name: "Park",
    meta: { title: "园区管理", keepAlive: true},
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/park/park")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
    // 判断是否需要登录才可以进入
    if (to.matched[0].meta.keepAlive) {
        // 这里依据 token 判断是否登录，可视情况修改
        const token = util.cookies.get('token');
        if (token && token !== 'undefined') {
            next();
        } else {
            // 没有登录的时候跳转到登录界面
            // 携带上登陆成功之后需要跳转的页面完整路径
            next({
                name: 'Login',
                query: {
                    redirect: to.fullPath
                }
            });
        }
    } else {
        // 不需要身份校验 直接通过
        next();
    }
})

export default router;
