//插件

// 错误捕获
import pluginError from '@/plugins/error';

export default {
    async install (Vue, options) {
        // 插件
        Vue.use(pluginError);
    }
}