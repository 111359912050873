import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//插件
import plugins from './plugins'
// 提示框
import vueSweetalert2 from '@/plugins/sweetalert2'
// 多语言
import i18n from '@/i18n'

if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    alert('本系统不支持ie，请使用Chrome、Firefox、Edge等现代浏览器访问！')
    reutrn
}


import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)

Vue.use(plugins)
Vue.use({
    i18n: (key, value) => i18n.t(key, value),
})

Vue.use(vueSweetalert2)

//css
import "./assets/css/public.css";
import "./assets/css/component.css";
import "./assets/css/style.css";

Vue.prototype.$Bus = new Vue();
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

new Vue({
    router,
    store,
    render: h => h(App),
    async created() {
        // 加载用户登录的数据
        this.$store.dispatch('admin/account/load')
    },
}).$mount("#app");
