import request from '@/plugins/request'

const res = 'task'

export default {
    saveAgreement(params){
        return request({
            url:`api/${res}/saveAgreement`,
            method:'post',
            data:params
        })
    },
    setStatus(params){
        return request({
            url:`api/${res}/setStatus`,
            method:'post',
            data:params
        })
    },
    taskRequirement(params){
        return request({
            url:`api/${res}/taskRequirement`,
            method:'get',
            params
        })
    }
}
