import request from '@/plugins/request'

const res = 'statistics'

export default {
    total(){
        return request({
            url:`api/${res}/total`,
            method:'get'
        })
    },
}
