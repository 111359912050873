<template>
    <el-header class="top_bar flex">
        <div class="top_bar_left">
            <div class="toggle-button" @click="toggleCollapse">
                <i v-if="isCollapse == false" class="el-icon-s-fold"></i>
                <i v-else class="el-icon-s-unfold"></i>
            </div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item>首页</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="top_bar_right flex">
            <el-dropdown>
                <div class="el-dropdown-link">
                    <div class="avatar flex">
            <!--<span class="avatar_img">-->
              <!--<img src="../../assets/images/avatar_default.jpeg" alt/>-->
            <!--</span>-->
                        <span class="avatar_name">{{ info.name }}</span>
                        <i class="el-icon-arrow-down el-icon--right"></i>
                    </div>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <!--<el-dropdown-item>个人信息</el-dropdown-item>-->
                    <el-dropdown-item command="loginout" @click.native="loginout()">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-header>
</template>

<script>
    import setting from '@/setting'
    import { mapActions, mapState } from 'vuex'
    import mixinPage from '@/mixins/page'
    export default {
        mixins: [mixinPage],
        data() {
            return {
                isCollapse: false,
            }
        },
        created() {
            this.load()
        },
        methods: {
            ...mapActions('admin/user', {
                load: 'load',
            }),
            ...mapActions('admin/account', {
                loginOutStore: 'logout',
            }),
            toggleCollapse() {
                this.isCollapse = !this.isCollapse
                this.$Bus.$emit('listingIsCollapse')
            },
            loginout() {
                this.loginOutStore({
                    confirm: this.logoutConfirm,
                    vm: this
                });
            },
        },
    }
</script>
