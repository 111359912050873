import company from './company'
import customer from './customer'
import project from './project'
import user from './user'
import model from './model'
import system from './system'
import task from './task'
import taker from './taker'
import statistics from './statistics'

export default {
    company,
    customer,
    project,
    user,
    model,
    system,
    task,
    taker,
    statistics
}
